<template>
  <rs-select
    :label="labelCalculated"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    @change="handleChange"
    :loading="isLoading"
    :custom-item="customItem"
  >
    <template v-slot:item="data">
      <v-list-item-content :class="data.item.is_active ? '' : 'text-muted'">
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <template slot="append-outer" v-if="can('edit-expense-type') && !hideAdd">
      <v-btn
        v-if="$refs.expenseTypeForm"
        small
        icon
        outlined
        @click="showAddForm"
        color="pink"
        :title="
          this.childrenOf ? 'Yeni gider tipi ekle' : 'Yeni gider türü ekle'
        "
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <ExpenseTypeBasicForm @created="handleCreated" ref="expenseTypeForm" />
      <ChildExpenseTypeForm
        @created="handleChildCreated"
        ref="childExpenseTypeForm"
      />
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";
import { ChildExpenseTypeForm } from "../../pages/expense/forms";
import { ExpenseTypeBasicForm } from "../../content/definitions/forms";

export default {
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Gider Türü",
    },
    showPassive: {
      type: Boolean,
      required: false,
      default: false,
    },
    customItem: {
      type: Boolean,
      required: false,
      default: true,
    },
    childrenOf: {
      type: Number,
      required: false,
      default: null,
    },
    hideAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [hasPermissions],
  components: { ChildExpenseTypeForm, ExpenseTypeBasicForm },
  computed: {
    ...mapGetters(["childExpenseTypeList", "cluster", "expenseTypeList"]),
    options() {
      let list = [];
      if (this.childrenOf) {
        for (const index in this.childExpenseTypeList) {
          if (this.childExpenseTypeList[index].parent_id === this.childrenOf) {
            list.push(this.childExpenseTypeList[index]);
          }
        }
      } else {
        list = this.expenseTypeList;
      }

      if (!this.showPassive) {
        list = list.filter((v) => v.is_active);
      }

      return list;
    },
    labelCalculated() {
      if (this.childrenOf) {
        return "Gider Tipi";
      }

      return "Gider Türü";
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
    },
    handleCreated(provider) {
      this.$emit("input", provider.id);
    },
    handleChildCreated(child) {
      this.$emit("input", child.id);
    },
    showAddForm() {
      if (this.childrenOf) {
        this.$refs.childExpenseTypeForm.show(null, this.childrenOf);
      } else {
        this.$refs.expenseTypeForm.show();
      }
    },
  },
};
</script>
