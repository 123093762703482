<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Faturaya Kalem Ekle</template>
    <template slot="body">
      <v-alert
        color="warning darken-1"
        border="left"
        dark
        v-if="hasInstallments"
      >
        Taksitli bir faturanın kalemlerini düzenliyorsunuz. Fatura toplam bedeli
        değişirse taksitler silinir ve tekrar oluşturmanız gerekir.
      </v-alert>
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-select-expense-type
              v-model="formData.expense_type_id"
              :label="$t('labels.expense_type')"
              :rules="[rules.required]"
            />
          </v-col>

          <v-col sm="6">
            <rs-select
              :label="$t('labels.expense_kind')"
              v-model="formData.child_expense_type_id"
              :items="childExpenseTypeList.filter((item) => item.is_active)"
              :disabled="!formData.expense_type_id"
              ref="childExpenseType"
            >
              <template slot="append-outer" v-if="can('edit-expense-type')">
                <v-btn
                  small
                  icon
                  outlined
                  color="pink"
                  @click="handleAddExpenseTypeClick"
                  :disabled="!formData.expense_type_id"
                  title="Yeni gider tipi ekle"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </rs-select>
          </v-col>

          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="
                amountDisabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 9999999.99),
                    ]
              "
              :disabled="amountDisabled"
              :hint="
                hasInstallments
                  ? 'Tutar değiştirirseniz fatura taksitleri silinecek.'
                  : ''
              "
            />
          </v-col>

          <v-col sm="6">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.maxLength(formData.description, 1000)]"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <ChildExpenseTypeForm
        ref="childExpenseTypeForm"
        @dismissed="$refs.childExpenseTypeForm.hide()"
        @saved="handleChildExpenseTypeFormSaved"
        parent-id-disabled
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasForm, hasPermissions } from "@/view/mixins";
import ChildExpenseTypeForm from "./ChildExpenseTypeForm";

export default {
  name: "InvoiceItemForm",
  mixins: [hasForm, hasPermissions],
  props: {
    amountDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasInstallments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ChildExpenseTypeForm,
  },
  computed: {
    ...mapGetters(["cluster"]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
  },
  data() {
    return {
      showExpenseForm: false,
    };
  },
  methods: {
    show(formData) {
      this.formData = formData
        ? Object.assign({}, formData)
        : {
            expense_type_id: null,
            child_expense_type_id: null,
            description: null,
            amount: null,
          };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      this.$nextTick(() => this.$emit("saved", formData));
      if (event && event.closeOnSave) {
        this.hide();
      }
    },
    handleAddExpenseTypeClick() {
      this.$refs.childExpenseTypeForm.show(null, this.formData.expense_type_id);
    },
    handleChildExpenseTypeFormSaved(child) {
      this.$refs.childExpenseType.pushOption(child);
      this.formData.child_expense_type_id = child.id;
    },
  },
};
</script>
