<template>
  <v-autocomplete
    :items="list"
    :loading="isLoading"
    :search-input.sync="search"
    :filter="() => true"
    item-text="door_number"
    :item-value="(item) => item.id"
    :label="label"
    v-bind="$attrs"
    hide-details="auto"
    @input="handleSearchInputChange"
    @change="handleChange"
    @click:clear="handleClearClick"
  >
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content>{{ data.item }}</v-list-item-content>
      </template>
      <template v-else>
        <template v-if="data.item.house_user_type">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.block_name }} - {{ data.item.door_number }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.house_user_type }}
              <template v-if="data.item.is_staying">
                | Burada oturuyor
              </template>
              <template v-if="data.item.is_spokesperson">
                | <strong>İlgili Kişi</strong>
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content
            >{{ data.item.block_name }}-{{
              data.item.door_number
            }}</v-list-item-content
          >
        </template>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { buildSearchParams } from "@/core/helpers";
import { debounce } from "@/core/plugins/debounce";

export default {
  props: {
    clusterId: {
      type: Number,
      required: true,
      default: null,
    },
    residentId: {
      type: Number,
      required: false,
      default: null,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      required: false,
      default: "BB seçin",
    },
  },
  watch: {
    search: debounce(function () {
      if (this.firstLoad) {
        this.firstLoad = false;
        return;
      }

      this.loadList();
    }, 250),
    clusterId() {
      this.loadList();
    },
    residentId() {
      this.loadList();
    },
    extraParams() {
      this.loadList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      list: [],
      isLoading: false,
      search: null,
      selectedName: null,
      selectedItem: {},
      firstLoad: null,
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      if (
        this.search &&
        (this.search === this.selectedName ||
          this.search === this.selectedItem.door_number)
      ) {
        return;
      }

      this.isLoading = true;

      const params = buildSearchParams(Object.assign({}, this.extraParams));

      if (this.residentId !== null) {
        params.resident_id = this.residentId;
      }

      if (this.search) {
        params.query = this.search;
      }

      params.per_page = 1000;

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading house list", error);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.firstLoad === null) {
            this.firstLoad = true;
            setTimeout(() => {
              this.firstLoad = false;
            }, 300);
          }
        });
    },
    handleChange(selectedId) {
      if (!selectedId) {
        this.selectedItem = {};
        this.$emit("change:option", { id: null, item: null });
        this.$emit("input", null);
        this.$emit("change", null);
        return false;
      }
      let selectedItem;

      for (const index in this.list) {
        if (this.list[index].id === selectedId) {
          selectedItem = Object.assign(this.list[index]);
          this.selectedItem = selectedItem;
          break;
        }
      }

      this.$emit("change:option", {
        id: selectedId,
        item: selectedItem,
      });
      this.$emit("input", selectedId);
      this.$emit("change", selectedId);

      this.selectedName = selectedItem.door_number;

      this.isLoading = true;
      this.$nextTick(() => {
        this.isLoading = false;
      });

      return false;
    },
    /**
     * Adds a new option to result list.
     * @param  {Object} item User Object
     * @return {Boolean}      True if added, false if already exists
     */
    pushOption(item) {
      for (const index in this.list) {
        if (this.list[index].id === item.id) {
          return false;
        }
      }
      this.list.push(item);

      return true;
    },
    handleClearClick() {
      this.handleChange(null);
    },
  },
};
</script>
