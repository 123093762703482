<template>
  <div>
    <v-btn
      color="red"
      outlined
      @click="handleClick"
      v-bind="$attrs"
      v-on="$listeners"
      small
      :disabled="disabled"
      title="Sil"
    >
      <v-icon v-if="showIcon || iconOnly">mdi-trash-can-outline</v-icon>
      <template v-if="!iconOnly">{{ this.label }}</template>
    </v-btn>
    <rs-confirm
      ref="confirm"
      :loading="loading"
      @confirmed="handleConfirmed"
      @cancelled="$refs.confirm.hide()"
    />
  </div>
</template>

<script>
// import { ConfirmDialog } from "@/view/content/ConfirmDialog";

export default {
  props: {
    confirmed: {
      type: Boolean,
      default: true,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    confirmText: {
      type: String,
      default: "Silmek istediğinizden emin misiniz?",
      required: false,
    },
    label: {
      type: String,
      default: "Sil",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // ConfirmDialog,
  },
  methods: {
    handleClick() {
      if (this.confirmed) {
        this.$refs.confirm.show(this.confirmText);
      } else {
        this.$emit("deleted");
      }
    },
    handleConfirmed() {
      this.$emit("deleted");
      this.$refs.confirm.hide();
    },
  },
};
</script>
