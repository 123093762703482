<template>
  <v-btn
    small
    outlined
    @click.capture="handleClick($event)"
    :color="color"
    :class="cssClass"
    :loading="isLoading"
    v-bind="$attrs"
    v-on="listeners"
    :id="elementId"
  >
    <slot>{{ label }}</slot>

    <v-tooltip v-if="elementId && tooltip" :activator="'#' + elementId" bottom>
      <span>{{ tooltip }}</span>
    </v-tooltip>

    <rs-confirm
      v-if="elementId && confirmRef"
      :id="confirmRef"
      :ref="confirmRef"
      :loading="isLoading"
      @confirmed="handleConfirmed"
      @cancelled="$refs[confirmRef].hide()"
    />
  </v-btn>
</template>

<script>
export default {
  name: "RsActionButton",
  props: {
    /**
     * Show spinner
     */
    isLoading: Boolean,
    /** Show a tooltip */
    tooltip: String,
    cssClass: {
      required: false,
      default: "ms-2",
    },
    color: {
      required: false,
      default: "pink",
    },
    confirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmText: {
      type: String,
      required: false,
      default: "Bunu yapmak istediğinizden emin misiniz?",
    },
  },
  computed: {
    listeners() {
      if (this.confirmed) {
        if (this.$listeners.click) {
          delete this.$listeners.click;
        }
      }

      return this.$listeners;
    },
    confirmRef() {
      if (this.elementId === undefined) {
        return undefined;
      }

      return this.elementId + "Confirm";
    },
  },
  data() {
    return {
      elementId: undefined,
      event: null,
      lastClick: null,
    };
  },
  methods: {
    handleClick(event) {
      this.event = event;

      event.stopPropagation();

      const now = new Date();
      if (this.lastClick && now - this.lastClick < 200) {
        return;
      }

      this.lastClick = now;

      if (this.confirmed && this.confirmRef) {
        this.$refs[this.confirmRef].show(this.confirmText);
      } else {
        this.$emit("click", this.event);
      }
    },
    handleConfirmed() {
      if (this.confirmRef) {
        this.$refs[this.confirmRef].hide();
        this.$emit("confirmed", this.event);
      }
    },
  },
  created() {
    this.elementId = "rsA-" + Math.round(Math.random() * 100000);
  },
};
</script>
