<template>
  <tr class="indigo lighten-5">
    <td
      v-for="(header, index) in headers"
      :key="index"
      :class="getClasses(header)"
    >
      <slot :name="'foot.' + header.value" v-bind:header="header">
        <span v-if="index === 0">{{ totalsText || $t("sum") }}</span>
        <span
          v-else-if="
            totals[header.value] !== null && totals[header.value] !== undefined
          "
        >
          <rs-table-cell-number
            :price="!notPrice && header.notPrice !== true"
            :value="totals[header.value]"
          />
        </span>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "FootTotals",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    totals: {
      type: Array,
      required: true,
    },
    totalsText: {
      type: String,
      default: null,
      required: false,
    },
    notPrice: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getClasses(header) {
      const classes = [];

      if (header.align) {
        classes.push("text-" + header.align);
      }

      if (header.cellClass) {
        if (header.cellClass.constructor.name === "Function") {
          classes.push(header["cellClass"]());
        } else {
          classes.push(header.cellClass);
        }
      }

      return classes;
    },
  },
};
</script>
