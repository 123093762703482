export default [
  {
    path: "/budget/definition",
    name: "budget.definition.list",
    component: () =>
      import("@/view/pages/budget/definition/BudgetDefinitionList.vue"),
  },
  {
    path: "/budget/definition/create",
    name: "budget.definition.create",
    component: () =>
      import("@/view/pages/budget/definition/BudgetDefinitionForm.vue"),
  },
  {
    path: "/budget/definition/:id/edit",
    name: "budget.definition.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/budget/definition/BudgetDefinitionForm.vue"),
  },
  {
    path: "/budget/invoice-items/active",
    name: "budget.invoice-items.active",
    component: () =>
      import("@/view/pages/budget/invoices/InvoiceItemActiveList.vue"),
  },
  {
    path: "/budget/invoice-items/open",
    name: "budget.invoice-items.open",
    component: () =>
      import("@/view/pages/budget/invoices/InvoiceItemOpenList.vue"),
  },
  {
    path: "/budget/wizard",
    name: "budget.wizard",
    component: () =>
      import("@/view/pages/budget/wizard/BudgetWizard.vue"),
  },
];
