import ApiService from "@/core/services/api.service";
import ClusterService from "@/core/services/cluster.service";
import CompanyService from "@/core/services/company.service";
import OptionsService from "@/core/services/options.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  hasPaymentWarning: false,
  matomoReadonlyAuthToken: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  roles(state) {
    return state.user?.roles?.map((item) => item.name) || [];
  },
  token() {
    return JwtService.getToken();
  },
  hasPaymentWarning() {
    return state.hasPaymentWarning;
  },
  matomoReadonlyAuthToken() {
    return state.matomoReadonlyAuthToken;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("sanctum/token", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          ApiService.setHeader();
          OptionsService.init();
          ClusterService.init();
          CompanyService.init();
          resolve(data);

          if (window._paq) {
            window._paq.push(["setUserId", data.data.user.id]);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    const tokenId = JwtService.getTokenId();
    if (window._paq) {
      window._paq.push(["resetUserId"]);
    }

    if (tokenId) {
      return ApiService.delete("sanctum/token/" + tokenId)
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          return data;
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
        });
    } else {
      context.commit(PURGE_AUTH);
      return Promise.resolve();
    }
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      OptionsService.init();
      ClusterService.init();
      CompanyService.init();
      ApiService.get("who-am-i")
        .then(({ data }) => {
          if (data.data.user.has_temporary_password) {
            this.logout();
          } else {
            context.commit(SET_AUTH, data.data);
          }

          if (window._paq) {
            window._paq.push(["setUserId", data.data.user.id]);
          }
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
          window.location.href = "/"; // TODO: do a better thing
          // context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password)
      .then(({ data }) => {
        context.commit(SET_PASSWORD, data);
        return data;
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    for (const itemKey in error) {
      const messageList = error[itemKey];
      for (const index in messageList) {
        Swal.fire({
          title: "Hata!",
          text: messageList[index],
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    }
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.hasPaymentWarning = data.has_payment_warning;
    state.errors = {};
    state.matomoReadonlyAuthToken = data.matomo_readonly_auth_token;
    JwtService.saveToken(data.token);
    if (data.token_id) {
      JwtService.saveTokenId(data.token_id);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
